import axios from "axios";
import React from "react";
import { encodeConfigURI } from "../../../utils/encodeConfigURI";
import {
  NotificationsVariants,
  useNotification,
} from "../../../hooks/useNotification";
import { useNavigate } from "react-router-dom";
import { APP_ROUTES } from "../../../constants/appRoutes";
import { useApiUrlLoader } from "../../../hooks/useApiUrlLoader";
import { AxiosContext } from "./AxiosContext";

interface Props {
  children: React.ReactNode;
}

export const AxiosProvider: React.FC<Props> = ({ children }) => {
  const getApiUrl = useApiUrlLoader();
  const showNotification = useNotification();
  const navigate = useNavigate();
  const instance = axios.create({
    headers: {
      // SET DEFAULT HEADERS
      "Access-Control-Max-Age": 600,
      "Content-Type": "application/json; charset=utf-8",
      "Access-Control-Allow-Origin": "*",
      // DISABLE NGROK CORS ERRORS
      "ngrok-skip-browser-warning": true,
    },
  });

  // REQUEST INTERCEPTOR
  instance.interceptors.request.use(async (config) => {
    // SETUP BASE URL
    config.baseURL = await getApiUrl();
    // ENCODE REQUEST QUERY
    return encodeConfigURI(config);
  });

  // RESPONSE INTERCEPTOR
  instance.interceptors.response.use(
    (config) => {
      // SHOW SUCCESS NOTIFICATION ON NON GET REQUESTS
      if (config.status === 200 && config.config.method !== "get") {
        showNotification("Success!", NotificationsVariants.INFO);
      }
      return config;
    },
    // ERRORS HANDLING
    (error) => {
      // DON'T SHOW NOTIFICATION IF REQUEST WAS CANCELED BY OUR SIGNAL
      if (error.code !== "ERR_CANCELED") {
        showNotification(
          error.response?.data?.message || error.message || "Unhandled error",
          NotificationsVariants.ERROR
        );
      }
      // UNAUTHORIZED LOGOUT
      if (
        error.response &&
        (error.response.status === 401 || error.response.status === 403)
      ) {
        navigate(APP_ROUTES.LOGIN);
      }
      throw error;
      // return Promise.reject(error);
    }
  );

  return (
    <AxiosContext.Provider value={instance}>{children}</AxiosContext.Provider>
  );
};
