export const API_ROUTES = {
  // AUTH
  LOGIN: "auth/login",
  LOGOUT: "auth/logout",
  // ADMIN ROUTES
  USERS: "user",
  // USER ROUTES
  CURRENT_USER: "user/current",
  // DOMAINS
  DOMAIN: "domains",
  // PRESETS
  PRESET: "presets",
  // MAIL CHECKER
  MAIL_CHECKER: "mail-checker",
} as const;
