import React from "react";
import { SnackbarProvider } from "notistack";
import { CurrentUserProvider } from "./currentUser";
import { InstallPWA } from "../../hooks/useInstallPWA";
import { Outlet } from "react-router-dom";
import { AxiosProvider } from "./axios";
import { ColorModeProvider } from "./colorMode";

// CONFIGURING ALL APP'S NECCESSARY PROVIDERS
export const ConfigureProviders: React.FC = () => {
  return (
    // MUI APP THEME PROVIDER
    <ColorModeProvider>
      {/* APP NOTIFICATION'S BAR PROVIDER */}
      <SnackbarProvider
        maxSnack={3}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        autoHideDuration={4000}
      >
        {/* CONFIGURED AXIOS INCTACE PROVIDER */}
        <AxiosProvider>
          {/* CURRENT USER PROVIDER */}
          <CurrentUserProvider>
            <Outlet />
          </CurrentUserProvider>
        </AxiosProvider>
      </SnackbarProvider>
      <InstallPWA />
    </ColorModeProvider>
  );
};
