import React, { lazy } from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { APP_ROUTES } from "./constants/appRoutes";
import { Layout } from "./layout";
import { ConfigureProviders } from "./core/providers";

const App: React.FC = () => {
  // SETUP LAZY LOADING
  const LoginPage = lazy(() => import("./pages/LoginPage"));
  const NotFoundPage = lazy(() => import("./pages/NotFoundPage"));
  const UsersPage = lazy(() => import("./pages/UsersPage"));
  const DomainsPage = lazy(() => import("./pages/DomainsPage"));
  const PresetsPage = lazy(() => import("./pages/PresetsPage"));
  const MailCheckerPage = lazy(() => import("./pages/MailCheckerPage"));

  return (
    <BrowserRouter>
      <Routes>
        <Route element={<ConfigureProviders />}>
          <Route path={"/"} element={<Navigate to={APP_ROUTES.LOGIN} />} />
          <Route
            path={APP_ROUTES.LOGIN}
            element={
              <React.Suspense>
                <LoginPage />
              </React.Suspense>
            }
          />
          <Route element={<Layout />}>
            <Route
              path={"*"}
              element={
                <React.Suspense>
                  <NotFoundPage />
                </React.Suspense>
              }
            />
            <Route
              path={APP_ROUTES.USERS}
              element={
                <React.Suspense>
                  <UsersPage />
                </React.Suspense>
              }
            />
            <Route
              path={APP_ROUTES.DOMAINS}
              element={
                <React.Suspense>
                  <DomainsPage />
                </React.Suspense>
              }
            />
            <Route
              path={APP_ROUTES.PRESETS}
              element={
                <React.Suspense>
                  <PresetsPage />
                </React.Suspense>
              }
            />
            <Route
              path={APP_ROUTES.MAIL_CHECKER}
              element={
                <React.Suspense>
                  <MailCheckerPage />
                </React.Suspense>
              }
            />
          </Route>
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default App;
